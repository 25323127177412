<script setup lang="ts">
import {
  darkTheme,
  dateEnUS,
  dateFrFR,
  enUS,
  frFR,
  GlobalThemeOverrides,
  NConfigProvider,
  NDialogProvider,
  NNotificationProvider,
  useOsTheme,
} from "naive-ui";
import Toast from "primevue/toast";
import { computed, provide } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import OToastMessage from "./components/ui/OToastMessage.vue";
import { loadLocaleMessages, setI18nLanguage } from "./i18n";
import { useLocalesStore } from "./stores/locales";
import { useRequestStore } from "./stores/request";

const router = useRouter();
const localesStore = useLocalesStore();
const requestStore = useRequestStore();
const osTheme = useOsTheme();

provide("version", __APP_VERSION__);

const naiveUILocale = computed(() => {
  switch (localesStore.locale || "fr") {
    case "fr":
      return frFR;
    default:
      return enUS;
  }
});

const naiveUIDateLocale = computed(() => {
  switch (localesStore.locale || "fr") {
    case "fr":
      return dateFrFR;
    default:
      return dateEnUS;
  }
});
const theme = computed(() => {
  return osTheme.value === "dark" ? darkTheme : null;
});

const lightThemeOverrides: GlobalThemeOverrides = {
  common: {
    primaryColor: "#3B82F6",
    primaryColorHover: "#2563EB",
    primaryColorPressed: "#1D4ED8",
    primaryColorSuppl: "#60A5FA",
  },
};

const darkThemeOverrides: GlobalThemeOverrides = {
  common: {
    primaryColor: "#5CCBFF",
    primaryColorHover: "#05B0FF",
    primaryColorPressed: "#0096DB",
    primaryColorSuppl: "#4EC4FA",
  },
};

const themeOverrides = computed(() => {
  return osTheme.value === "dark" ? darkThemeOverrides : lightThemeOverrides;
});

const i18n = useI18n({ useScope: "global" });

localesStore.$subscribe(async (mutation, state) => {
  if (!i18n.availableLocales.includes(state.locale)) {
    await loadLocaleMessages(i18n, state.locale);
  }

  // set i18n language
  setI18nLanguage(i18n, state.locale);
});

router.beforeEach((to, from, next) => {
  if (i18n.te("routes." + String(to.name))) {
    document.title = i18n.t("routes." + String(to.name)) + " - Spectrum Lab";
  } else {
    document.title = "Spectrum Lab";
  }
  next();
});

requestStore.$subscribe((mutation, state) => {
  if (state.nbLoading > 0) {
    document.body.style.cursor = "wait";
  } else {
    document.body.style.cursor = "default";
  }
});
</script>

<template>
  <n-config-provider
    :theme="theme"
    :locale="naiveUILocale"
    :date-locale="naiveUIDateLocale"
    :theme-overrides="themeOverrides"
  >
    <Toast>
      <template #container="{ message, closeCallback }">
        <OToastMessage :message="message" :close-callback="closeCallback" />
      </template>
    </Toast>
    <n-notification-provider placement="top-right">
      <n-dialog-provider>
        <router-view />
      </n-dialog-provider>
    </n-notification-provider>
  </n-config-provider>
</template>

<style scoped>
.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
}
.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}
.logo.vue:hover {
  filter: drop-shadow(0 0 2em #42b883aa);
}
</style>
